import React, { useContext, useEffect, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import { saveAs } from "file-saver";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography, Checkbox, Pagination, Select, MenuItem, Stack, Icon } from "@mui/material";
import axios from "axios";
import { CustomerUserContext } from "App";
import Swal from "sweetalert2";
import { Grid } from "@material-ui/core";
import Star from "./iconComponents/Star";
import TableSort from "./TableSort";
import DateTimeFormatter from "./DateTimeFormatter";

// Customized checkbox
const OrangeCheckbox = styled(Checkbox)(() => ({
  color: "#FF7701", // Orange color
  "&.Mui-checked": {
    color: "#FF7701", // Orange color when checked
  },
}));

function AppointmentTable({
  table,
  refresh,
  setRefresh,
  openAppointment,
  handleFavoriteClick,
  isFavourite,
}) {
  const [customerUser] = useContext(CustomerUserContext);
  const useStyles = makeStyles(() => ({
    tableData: {
      height: "60vh",
      overflow: "auto",
      marginTop: "20px",
    },
    "@media (min-width: 1120px)": {
      tableData: {
        height: "80vh",
      },
    },
    TableContainer: {
      padding: "0 2%",
    },
    tableHead: {
      borderBottom: "0",
      margin: "0",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: "#010101",
    },
    table: {
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: "400",
      borderTop: "1px solid #5F5F5F26",
      color: "#010101",
    },
    tableRow: { borderBottom: "1px solid #50555C" },
    pagination: { float: "right", padding: "2% 0" },
    itemStatus: {
      borderRadius: "5px",
      padding: "4% 0",
      fontSize: "12px",
      cursor: "pointer",
      textAlign: "center",
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#626161",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Poppins",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#010101",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectAll = () => {
    if (selectedItems.length === table.rows.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(table.rows.map((item) => item.id));
    }
  };

  const handleCheckboxClick = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  console.log("pagesss", page);

  const handleFavoriteSort = () => {
    if (sortingOrderBy === "is_favorite") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("is_favorite");
      setSortingOrder("asc");
    }
  };

  const handleStatusSort = () => {
    if (sortingOrderBy === "status") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("status");
      setSortingOrder("asc");
    }
  };
  const handleLeadSort = () => {
    if (sortingOrderBy === "lead_identificator") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("lead_identificator");
      setSortingOrder("asc");
    }
  };
  const handleDateTimeSort = () => {
    if (sortingOrderBy === "appointment_at") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("appointment_at");
      setSortingOrder("asc");
    }
  };
  const handleCreatedAtSort = () => {
    if (sortingOrderBy === "created_at") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("created_at");
      setSortingOrder("asc");
    }
  };
  const handleCampaignSort = () => {
    if (sortingOrderBy === "campaign_name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("campaign_name");
      setSortingOrder("asc");
    }
  };
  const handleCustomerNameSort = () => {
    if (sortingOrderBy === "customer_name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("customer_name");
      setSortingOrder("asc");
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.rows.length - page * rowsPerPage);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getStatusBackgroundColor(status) {
    switch (status) {
      case "Success":
        return "#2F9D6814";
      case "Approved":
        return "#FF8B2214";
      case "In progress":
        return "#FF838314";
      case "Not Confirmed":
        return "#92CAFF1A";
      default:
        return "";
    }
  }

  function getColor(status) {
    switch (status) {
      case "Success":
        return "#71D8A6";
      case "Approved":
        return "#FF9455";
      case "In progress":
        return "#FF8383";
      case "Not Confirmed":
        return "#75BDFF";
      default:
        return "";
    }
  }

  const handleCardClick = (id, className) => {
    openAppointment({ id, class_name: className });
  };

  const [dropdownRowValue, setDropdownRowValue] = useState(rowsPerPage); // State for dropdown value

  // Function to handle dropdown change
  const handleDropdownRowChange = (event) => {
    setRowsPerPage(event.target.value > table.rows.length ? table.rows.length : event.target.value);
    // You can perform actions based on the selected value here
    setDropdownRowValue(event.target.value);
  };

  const [dropdownValue, setDropdownValue] = useState(""); // State for dropdown value
  const [search, setSearch] = useState("");
  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Function to handle dropdown change
  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    // You can perform actions based on the selected value here
  };

  const handleExport = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(`/api/customers/${customerUser.customer.id}/campaigns/0/appointments/export`, payload, {
        responseType: "blob",
      })
      .then((res) => {
        saveAs(res.data, "appointments-export.csv");
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleExportToGoHighLevel = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/ghl-export`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleExportToWebhook = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/webhook-export`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/review-state-update`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  useEffect(() => {
    setPage(0);
  }, [search, isFavourite]);

  let filterRows;
  const searchLower = search.toLowerCase();

  if (isFavourite === 1) {
    filterRows = table.rows.filter((item) => item.is_favorite === true);
    if (searchLower) {
      filterRows = filterRows.filter((item) =>
        Object.values(item).some((value) => String(value).toLowerCase().includes(searchLower))
      );
    }
  } else if (searchLower) {
    filterRows = table.rows.filter((item) =>
      Object.values(item).some((value) => String(value).toLowerCase().includes(searchLower))
    );
  } else {
    filterRows = table.rows;
  }

  const paginationRows =
    rowsPerPage > 0
      ? stableSort(filterRows, getComparator(sortingOrder, sortingOrderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : stableSort(filterRows, getComparator(sortingOrder, sortingOrderBy));

  const paginationCount = Math.ceil(filterRows.length / rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" direction={{ xs: "column", md: "row" }}>
        <Grid item xs={12} md={6}>
          <Typography
            fontSize="20px"
            fontWeight="600"
            color="#010101"
            textAlign={{ sm: "center", md: "left" }}
            ml={3}
            mb={2}
          >
            Scheduled Appointments
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              sx={{
                backgroundColor: "#F7F7F7",
                borderRadius: "5px",
                paddingTop: "1%",
                paddingBottom: "1%",
                paddingLeft: "1%",
              }}
              alignItems="center"
            >
              <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
              <input
                style={{
                  border: "0",
                  background: "transparent",
                  outline: "none",
                }}
                type="text"
                value={search}
                placeholder="Search Appointment"
                onChange={onSearchChange}
              />
            </Stack>
            <Select
              sx={{ height: "20px", width: "80px", border: "none", marginRight: "30px" }}
              value={dropdownRowValue}
              onChange={handleDropdownRowChange}
              displayEmpty
              inputProps={{ "aria-label": "Action" }}
              multiple={false} // Ensure only single selection
              variant="standard" // Set variant to "standard"
            >
              <MenuItem value={5} checked={dropdownRowValue === 5}>
                5
              </MenuItem>
              <MenuItem value={10} checked={dropdownRowValue === 10}>
                10
              </MenuItem>
              <MenuItem value={25} checked={dropdownRowValue === 25}>
                25
              </MenuItem>
              <MenuItem value={50} checked={dropdownRowValue === 50}>
                50
              </MenuItem>
              <MenuItem value={100} checked={dropdownRowValue === 100}>
                100
              </MenuItem>
            </Select>
          </Stack>
        </Grid>
      </Grid>
      <div className={classes.tableData}>
        <TableContainer className={classes.TableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <OrangeCheckbox
                    checked={selectedItems.length === table.rows.length}
                    onChange={handleSelectAll}
                  />
                  <br />
                  <Select
                    sx={{ height: "20px", width: "80px", border: "none" }}
                    value={dropdownValue}
                    onChange={handleDropdownChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Action" }}
                    multiple={false} // Ensure only single selection
                    variant="standard" // Set variant to "standard"
                  >
                    {/* <MenuItem value="">&nbsp;</MenuItem> */}
                    <MenuItem value="" disabled>
                      <em>Action</em>
                    </MenuItem>
                    <MenuItem value="export" onClick={handleExport}>
                      Export
                    </MenuItem>
                    {customerUser.customer.gohighlevel_activated === 1 &&
                      customerUser.customer.gohighlevel_api_key && (
                        <MenuItem value="export" onClick={handleExportToGoHighLevel}>
                          Export to goHighLevel
                        </MenuItem>
                      )}
                    {customerUser.customer.webhook_urls_activated === 1 &&
                      customerUser.customer.webhook_urls && (
                        <MenuItem value="export" onClick={handleExportToWebhook}>
                          Export to webhook(s)
                        </MenuItem>
                      )}
                    <MenuItem value="accept" onClick={handleAccept}>
                      Accept
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "is_favorite" ? sortingOrder : "none"}
                  onClick={handleFavoriteSort}
                >
                  Fav
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "status" ? sortingOrder : "none"}
                  onClick={handleStatusSort}
                >
                  Status
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "lead_identificator" ? sortingOrder : "none"}
                  onClick={handleLeadSort}
                >
                  Lead
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "appointment_at" ? sortingOrder : "none"}
                  onClick={handleDateTimeSort}
                >
                  Date/Time
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "campaign_name" ? sortingOrder : "none"}
                  onClick={handleCampaignSort}
                >
                  Campaign
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "customer_name" ? sortingOrder : "none"}
                  onClick={handleCustomerNameSort}
                >
                  Customer Admin
                </TableSort>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "created_at" ? sortingOrder : "none"}
                  onClick={handleCreatedAtSort}
                >
                  Created At
                </TableSort>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationRows.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <OrangeCheckbox
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleCheckboxClick(item.id)}
                    />
                  </TableCell>
                  <TableCell
                    key={item.id}
                    className={classes.table}
                    scope="row"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleFavoriteClick(item);
                    }}
                  >
                    <Star fill={item.is_favorite === true ? "#EBCB24" : "transparent"} />
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    <Typography
                      onClick={() => handleCardClick(item.id, item.class_name)}
                      sx={{
                        backgroundColor: getStatusBackgroundColor(item.status),
                        color: getColor(item.status),
                      }}
                      className={classes.itemStatus}
                    >
                      {item.status}
                    </Typography>
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.lead_identificator.replace("Firmenname: ", "")}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    <DateTimeFormatter dateTime={item.appointment_at} />
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.campaign_name}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.customer_name}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    <DateTimeFormatter dateTime={item.created_at} />
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Pagination
            className={classes.pagination}
            count={paginationCount}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </ThemeProvider>
  );
}

AppointmentTable.propTypes = {
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  openAppointment: PropTypes.func.isRequired,
  handleFavoriteClick: PropTypes.func.isRequired,
  refresh: PropTypes.array.isRequired,
  setRefresh: PropTypes.func.isRequired,
  isFavourite: PropTypes.number.isRequired,
};

export default AppointmentTable;
