import React from "react";
import { Dialog, Box, DialogContent } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: "700px",
  },
}));

const JotformModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
      <Box width={{ sm: "500px", lg: "600px", md: "500px" }}>
        <DialogContent style={{ padding: "0" }}>
          <iframe
            id="JotFormIFrame-232647544615359"
            title="Campaign Onboarding"
            allowTransparency="true"
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/apntmnt/Onboarding"
            frameBorder="0"
            style={{
              width: "100%",
              height: "1150px",
              border: "none",
            }}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

JotformModal.propTypes = {
  open: PropTypes.any.isRequired,
  setOpen: PropTypes.any.isRequired,
};

export default JotformModal;
